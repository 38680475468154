import React, { useContext } from 'react';
import ListIcon from '@material-ui/icons/List';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import Tooltip from '@material-ui/core/Tooltip';
import AppContext from '../context/AppContext';
import { trackAction } from '../util/Tracking';

const FeedType = () => {
	const { state: { feedType }, setFeedType } = useContext(AppContext);

	const handleFeedType = (newFeedType) => {
		let feedInfo = JSON.parse(localStorage.getItem('feed')),
			duration = (Date.now() - feedInfo.date) / 1000;
		trackAction({
			eventCategory: 'Feed',
			eventAction: `Change to ${newFeedType}`,
			eventValue: duration

		});
		setFeedType(newFeedType);
	}
	return (
		<div className="row scoop-subheader__feed-types">
			<div className={`col-6 scoop-subheader__feed-type ${feedType === 'list' ? 'active' : ''}`} onClick={() => handleFeedType('list')}>
				<Tooltip title="List View" enterDelay={500}>
					<ListIcon className="scoop-subheader__feed-icon" />
				</Tooltip>
			</div>
			<div className={`col-6 scoop-subheader__feed-type ${feedType === 'grid' ? 'active' : ''}`} onClick={() => handleFeedType('grid')}>
				<Tooltip title="Grid View" enterDelay={500}>
					<ViewCompactIcon className="scoop-subheader__feed-icon" />
				</Tooltip>
			</div>
		</div>
	)
}
export default FeedType;