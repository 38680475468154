import React, { createRef, useContext } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import ListArticle from './ListArticle';
// import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
// import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
// import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
// import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import AppContext from '../context/AppContext';
import Social from './Social';
import { trackAction } from '../util/Tracking';

const GridArticle = ({ article, specialFeed, history, forcedType }) => {
    const { _id: id, title, urlToImage, imageUrlsObject, category, source, url, liked, disliked, pinned } = article;
    const SHARE_URLS = {
        twitter: `http://www.twitter.com/intent/tweet?hashtags=Scoop&text=${encodeURI('Shared via Scoop')}&url=${encodeURI(url)}`,
        linkedin: `https://www.linkedin.com/shareArticle?mini=true&source=${encodeURI('Shared via Scoop')}&url=${encodeURI(url)}`,
        email: `mailto:?body=${encodeURI(url)}&subject=${encodeURI('Shared via Scoop: ' + title)};`,
        facebook: `https://www.facebook.com/sharer.php?hashtag=${encodeURIComponent('#Scoop')}&quote=${encodeURI('Shared via Scoop')}&u=${encodeURI(url)}`
    }
    const { state: { sources, feedType, currentCategory } } = useContext(AppContext);
    const modalRef = createRef();
    const optionsRef = createRef();

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const shareClick = (e) => {
        stopPropagation(e);
        modalRef.current.classList.toggle("show");
        optionsRef.current.classList.toggle("hide");
    }

    const closeClick = (e) => {
        stopPropagation(e);
        modalRef.current.classList.toggle("show");
        optionsRef.current.classList.toggle("hide");
    }

    const articleClick = () => {
        trackAction({
            eventCategory: 'Feed',
            eventAction: 'Click Story from ' + currentCategory,
            eventLabel: title,
            feedType
        });
    }


    const handleShareClick = (e, actionType, width = 800, height = 600, windowTitle = '_blank') => {
        stopPropagation(e);
        const shareUrl = SHARE_URLS[actionType];

        if (actionType === 'email') {
            var mail = document.createElement("a");
            mail.href = shareUrl;
            mail.click();
        } else {
            if (width === 0 || height === 0) {
                window.open(shareUrl, windowTitle);
            } else {
                const left = (window.screen.width - width) / 2;
                const top = (window.screen.height - height) / 4;
                window.open(shareUrl, windowTitle, `width=${width}, height=${height}, left=${left}, top=${top}`);
            }
        }

    }

    return (feedType === 'grid' || forcedType === 'grid') ? (
        <div className="scoop-grid__item">
            <NavLink className="scoop-grid__options" to={`/details/${id}`} onClick={articleClick} >
                <Tooltip title="Actions" enterDelay={500}><img src="/options.svg" alt="Options" className="scoop-grid__option-icon" ref={optionsRef} onClick={stopPropagation} /></Tooltip>
                <div className="scoop-grid__actions" onClick={stopPropagation}>
                    {/* {liked
                        ? <Tooltip title="Like" enterDelay={500}><ThumbUpAltIcon className="scoop-grid__action" /></Tooltip>
                        : <Tooltip title="Like" enterDelay={500}><ThumbUpOutlinedIcon className="scoop-grid__action" /></Tooltip>

                    }
                    {disliked
                        ? <Tooltip title="Dislike" enterDelay={500}><ThumbDownAltIcon className="scoop-grid__action" /></Tooltip>
                        : <Tooltip title="Dislike" enterDelay={500}><ThumbDownOutlinedIcon className="scoop-grid__action" /></Tooltip>
                    }
                    {specialFeed !== 'pinned' && (pinned
                        ? <Tooltip title="Pin" enterDelay={500}><img src="/pin.svg" alt="Pin" className="scoop-grid__action"></img></Tooltip>
                        : <Tooltip title="Pin" enterDelay={500}><img src="/pin_outlined.svg" alt="Pin" className="scoop-grid__action"></img></Tooltip>
                    )}
                    {specialFeed && <Tooltip title="Delete" enterDelay={500}><DeleteOutlineIcon className="scoop-grid__action" /></Tooltip>} */}
                    <Tooltip title="Share" enterDelay={500}><ShareOutlinedIcon className="scoop-grid__action" onClick={shareClick} /></Tooltip>

                </div>
                <Tooltip title={source.name} enterDelay={500} className="scoop-grid__logo">{
                    sources[source.name] ?
                        <img alt="Logo" src={`data:image/svg+xml;base64,${sources[source.name]}`}></img> :
                        <span>{(source.name)}</span>
                }</Tooltip>
            </NavLink>
            <div className="scoop-grid__image-wrapper">
                <img src={imageUrlsObject && imageUrlsObject['scaled'] ? imageUrlsObject['scaled'] : urlToImage} className="scoop-grid__image" alt="..." />
                <div className="scoop-grid__info">
                    <div className="scoop-grid__title">{title}</div>
                    <div className={`scoop-grid__category ${category}`}>{category}</div>
                </div>
            </div>
            <div className="share-modal" ref={modalRef} onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                <CancelOutlinedIcon className="share-modal__close" onClick={closeClick} />
                <Social shareLevel="article" articleTitle={title} location="grid" innerClass="share-modal__share-icon" textClass="share-modal__title" clickHandler={handleShareClick} color="white" />
            </div>
        </div>
    ) : (<ListArticle article={article} handleShareClick={handleShareClick} newsClick={articleClick} logo={sources[source && source.name]}></ListArticle>);
};

export default withRouter(GridArticle);