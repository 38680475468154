import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Details from '../components/Details';
import Grid from '../components/Grid';
import VerticalMore from '../components/VerticalMore';
import Loader from 'react-loader-spinner';
import { axiosInstance as axios } from '../api/axios';
import { Helmet } from "react-helmet";
import AppContext from '../context/AppContext';
import GridHeader from '../components/Headline';


const DetailsPage = ({ history: { location: { pathname } } }) => {
    const [loaded, setLoaded] = useState(false);
    const [article, setArticle] = useState(null);
    const [verticalMoreArticles, setVerticalMoreArticles] = useState([]);
    const [moreArticles, setMoreArticles] = useState([]);
    const [error, setError] = useState(null);
    const { setCategory } = useContext(AppContext);
    let { id } = useParams();

    useEffect(() => {
        async function getData() {
            try {
                setLoaded(false);
                setError(null);
                const { data } = await axios.get(`article?more=23&id=${id}&hasPicture=true`);
                setArticle(...data.slice(0, 1));
                setVerticalMoreArticles(data.slice(1, 4));
                setMoreArticles(data.slice(4));
                setLoaded(true);
                setCategory(data[0].category);
            } catch (error) {
                setError("This article doesn't exist.")
                setLoaded(true);
            }
        }
        getData();
        // eslint-disable-next-line
    }, [pathname]);

    return (
        <div>
            {article &&
                <Helmet>
                    <title>{`Scoop - ${article.title}`}</title>
                </Helmet>
            }
            {!loaded
                ? <div className="scoop-loader">
                    <Loader
                        type="Grid"
                        color="#FF008A"
                    />
                </div>
                : <>
                    {error ? <div className="scoop-not-exist">{error}</div>
                        :
                        <div>
                            <div className="row scoop-details">
                                <GridHeader category={article && article.category} lineClass={`scoop-details__${article.category}-line`} />
                                <div className="col-12 col-md-9">
                                    <div className="row">
                                        <Details article={article} />
                                    </div>
                                </div>
                                <div className="col-md-3 scoop-details__vertical-more">
                                    <VerticalMore articles={verticalMoreArticles} />
                                </div>
                            </div>
                            <div className="row scoop-details__feed">
                                <div className="col-12">
                                    <Grid category={article.category} articles={moreArticles} more={true} />
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </div >
    );
}

export default DetailsPage;