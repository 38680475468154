import React, { useEffect, useState, useContext } from 'react';
import { axiosInstance as axios } from '../api/axios';
import Select from 'react-select'
import AppContext from '../context/AppContext';
import GridHeader from './Headline';
import SearchIcon from '@material-ui/icons/Search';

const AreaPicker = () => {
	const [areas, setAreas] = useState([]);
	const { state: { localArea }, setLocalArea } = useContext(AppContext);
	useEffect(() => {
		let fetchData = async () => {
			const { data } = await axios.get('local-news-areas');
			setAreas(data.map((area => { return { value: area, label: area } })));
		}
		fetchData();
	}, [])
	const handler = (item) => {
		setLocalArea(item.value);
	}

	const DropdownIndicator = props => {
		return (
			<SearchIcon />
		);
	};


	return (
		<div className="row scoop-local">
			<GridHeader category='Get Your Local News' lineClass='scoop-details__local-line' />
			<div className="scoop-local__picker-container">
				<div className="scoop-local__picker-label">Select a city below or search your location</div>
				<Select
					classNamePrefix="scoop-local"
					className="scoop-local__select"
					options={areas}
					value={{ value: localArea, label: localArea }}
					onChange={handler}
					components={{ DropdownIndicator }}
					theme={theme => ({
						...theme,
						borderRadius: 0,
						colors: {
							...theme.colors,
							primary25: '#DDD',
							primary50: '#CCC',
							primary: '#F16334',
						},
					})}
				>
				</Select>
			</div>
		</div>
	);

}

export default AreaPicker;