import { createContext } from 'react';


let feed = localStorage.getItem('feed');

if (!feed) {
    feed = { feedType: 'grid', date: Date.now() };
    localStorage.setItem('feed', JSON.stringify(feed));
} else {
    feed = JSON.parse(feed);
}

const initialState = {
    currentCategory: 'general',
    articles: [],
    categories: [],
    sources: {},
    searchTerm: '',
    localArea: '',
    feedType: feed.feedType || 'grid',
    socialLinks: {
        facebook: 'https://www.facebook.com/trustthescoop',
        twitter: 'https://twitter.com/myscoopnews1',
        pinterest: 'https://www.pinterest.com/myscoopnews',
        instagram: 'https://www.instagram.com/myscoopnews/'
    }
};

const AppContext = createContext(initialState);
export default AppContext;