import React, { useContext, useEffect, useRef } from 'react';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import ScrollToTop from 'react-router-scroll-top';

import Header from './components/Header';
import HomePage from './pages/HomePage';
import DetailsPage from './pages/DetailsPage';
import AppContext from './context/AppContext';
import axios from 'axios';
import Footer from './components/Footer';
import About from './pages/About';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Copyright from './pages/Copyright';
import Faq from './pages/Faq';
import Subscribe from './pages/Subscribe';
import { trackAction } from './util/Tracking'

const App = () => {
  const { setSources } = useContext(AppContext);
  const scrollerRef = useRef();

  const onScroll = () => {
    let opacity = (document.scrollingElement.scrollTop / 500 - 1);
    if (opacity > -1) {
      scrollerRef.current.style.opacity = opacity;
      if (opacity <= 0) {
        scrollerRef.current.style.display = 'none';

      } else {
        scrollerRef.current.style.display = 'block';
      }
    }
  }
  const handleScrollerClick = () => {
    document.scrollingElement.scrollTop = 0;
    scrollerRef.current.style.display = 'none';
    trackAction({
      eventCategory: 'Feed',
      eventAction: 'Scroll Button Up'
    })
  }

  useEffect(() => {
    async function getSources() {
      try {
        const response = await axios.get('https://api.sideviral.com/api/v1/sources-logos');
        setSources(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    getSources();
    scrollerRef.current.style.opacity = 0;
    scrollerRef.current.style.transform = 'rotate(180deg)';
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='app' >
      <Router >
        <Header />
        <Switch>
          <Route exact path='/' render={(props) => { scrollerRef.current && (scrollerRef.current.style.opacity = 0); return <HomePage {...props} /> }} />
          <Route exact path={"/(business|entertainment|general|health|science|sports|technology|politics|celebrities|diy|local)"} render={(props) => { scrollerRef.current && (scrollerRef.current.style.opacity = 0); return <HomePage {...props} /> }} />
          <Route exact path='/details/:id' render={(props) => { scrollerRef.current && (scrollerRef.current.style.opacity = 0); return <DetailsPage {...props} /> }} />
          <Route exact path='/about' component={About} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/terms' component={Terms} />
          <Route exact path='/copyright' component={Copyright} />
          <Route exact path='/faq' component={Faq} />
          <Route exact path='/subscribe' component={Subscribe} />
          <Redirect to='/' />
        </Switch>
        <Footer />
        <ScrollToTop />
      </Router>
      <Tooltip title="Go Back" enterDelay={500}>
        <div ref={scrollerRef} className="sc-scroller" onClick={handleScrollerClick}>
          <img src="/white_arrow.svg" alt="..." className="sc-arrow" />
        </div>
      </Tooltip>
    </div>
  );
}

export default App;
