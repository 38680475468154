import React, { useContext } from 'react';
import AppContext from '../context/AppContext';
import { trackAction } from '../util/Tracking';

const Social = ({ shareLevel = 'app', articleTitle, location,  color = 'blue', outerClass, innerClass, textClass, clickHandler }) => {
    const { state: { socialLinks } } = useContext(AppContext);

    const socialAnalyticsMap = (network) => {
        let subject = (shareLevel === 'app' ? 'Website' : 'Story');
        let regularName = network.charAt(0).toUpperCase() + network.slice(1);
        if (network === 'mail')
            subject = '';
        return `${subject} ${regularName} from ${location}`.trim();
    }
    const onClick = network => {
        trackAction({
            eventCategory: 'User Relationship',
            eventAction: socialAnalyticsMap(network)
        });
        window.open(socialLinks[network]);
    }
    return (
        <div className={outerClass}>
            {
                shareLevel === 'app' ?

                    ["pinterest", "instagram", "twitter"].map((network, index) => {
                        return <img
                            key={index}
                            onClick={() => { onClick(network) }}
                            src={`/social/${network}_circle_${color === 'black' ? 'blk' : (color === 'white' ? 'wht' : 'blue')}.svg`}
                            alt={network.substring(0, 2)}
                            className={innerClass}></img>
                    })

                    :
                    <>
                        <span className={textClass}>Share</span>
                        {
                            ["facebook", "linkedin", "email", "twitter"].map((network, index) => {
                                return <img
                                    key={index}
                                    onClick={(e) => {
                                        trackAction({
                                            eventCategory: 'Social',
                                            eventAction: socialAnalyticsMap(network),
                                            eventLabel: articleTitle
                                        });
                                        clickHandler(e, network);
                                    }}
                                    src={`/social/${network}_circle_${color === 'white' ? 'white.png' : 'blue.svg'}`}
                                    alt={network.substring(0, 2)}
                                    className={innerClass}></img>
                            })
                        }
                    </>
            }
        </div>
    );
};

export default Social;