import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div className="scoop-legal">
            <h1 className="scoop-legal__title">What's the Scoop?</h1>
            <p>Scoop's mission is to be the fastest way to filter, read, and share the news you care about. In today's culture, there are so many great stories and new events to read about. But how do you find them in all the fluff? We design with you in mind. Aside from our excellent website, we consolidate news from 100s of sources right into a clean, sliding window within your browser. Instantly access news at your convenience by opening and closing the window using the orange arrow button. You can quickly search, share, and pin articles in just a few clicks!</p>
            <p>Learn more about our browser extension <a href="https://chrome.google.com/webstore/detail/scoop-news-fun/fpknilpohkoogikcmohlokolcknkagoi" target="_blank" rel="noopener noreferrer">here</a>.</p>
            <p>We currently offer news about Health & Wellness, Science, DIY, Entertainment, Technology, Politics, Finance, Celebrities, Sport, Business, and much more. Our category and news source lists are always growing, so you'll never miss a story!</p>
            <p></p>
            <p>Save time. Be Smarter. Scoop.</p>
            <p></p>
            <p>We're always open to new ideas and suggestions. Let us know what you think!</p>
            <p></p>
            <div>
                <Link to="/contact">Contact Us</Link>
            </div>
            <div>
                <a href="https://airtable.com/shrFK1VmxnLjgHYuD" target="_blank" rel="noopener noreferrer">Feedback Survey</a>
            </div>
        </div>
    );
};

export default About;