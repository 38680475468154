import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'block'
    },
    links: {
        margin: '15px'
    }
}));

const Faq = () => {
    const classes = useStyles();
    return (
        <div className="scoop-legal">
            <h1 className="scoop-legal__title">Frequently Asked Questions</h1>

            <div>
                <ExpansionPanel defaultExpanded={true}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        I'm having trouble removing the Scoop app from my browser. How can I uninstall?
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.root}>
                        <div>
                            We're sad that you're leaving but completely understand. Below are uninstall instruction links for all browsers.
                        </div>
                        <div className={classes.links}>Chrome: <a href="https://support.google.com/chrome_webstore/answer/2664769?hl=en&ref_topic=6238977" target="_blank" rel="noopener noreferrer">Uninstall Instructions</a></div>
                        <div className={classes.links}>Firefox: <a href="https://support.mozilla.org/en-US/kb/disable-or-remove-add-ons#w_removing-extensions" target="_blank" rel="noopener noreferrer">Uninstall Instructions</a></div>
                        <div className={classes.links}>Edge: <a href="https://support.microsoft.com/en-us/help/4027935/microsoft-edge-add-or-remove-browser-extensions" target="_blank" rel="noopener noreferrer">Uninstall Instructions</a></div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        </div>
    );
};

export default Faq;