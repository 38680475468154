export default function reducer(state, { type, payload }) {
    switch (type) {
        case 'SET_CATEGORY':
            return {
                ...state,
                currentCategory: payload
            }
        case 'SET_ARTICLES':
            return {
                ...state,
                articles: payload,
            }
        case 'SET_CATEGORIES':
            return {
                ...state,
                categories: payload
            }
        case 'SET_SOURCES':
            return {
                ...state,
                sources: payload
            }
        case 'SET_SEARCH_TERM':
            return {
                ...state,
                searchTerm: payload
            }
        case 'SET_FEED_TYPE':
            return {
                ...state,
                feedType: payload
                }
        case 'SET_LOCAL_AREA':
            return {
                ...state,
                localArea: payload
                }
        default:
            return state
    }
}