import React from 'react';

const CWSButton = () => {

    const onClick = () => {
        window.open('https://chrome.google.com/webstore/detail/scoop-news-fun/fpknilpohkoogikcmohlokolcknkagoi');
    }

    return (
        <div className="cws-button" onClick={onClick}>
            <span className="cws-button__bold">Get Scoop</span> It's Free
        </div>
    );
};

export default CWSButton;