
export const Tracker = (function (destinationOrigin, destinationId) {
  let _commFrame = null,
    buffer = [],
    ready = false,
    initalized = false;

  const createCommunicationFrame = () => {
    let commFrame = document.createElement("iframe");
    commFrame.src = destinationId;
    commFrame.setAttribute("sandbox", "allow-scripts allow-same-origin");
    commFrame.style.visibility = 'hidden';
    return commFrame;
  };

  const send = (eventData) => {
    if (eventData) {
      buffer.push(eventData);
    }

    if (!ready) return;

    const destination = _commFrame.contentWindow;
    while (buffer.length) {
      destination.postMessage(
        { type: "event", data: buffer.pop() },
        destinationOrigin
      );
    }
  };

  const init = async function () {
    if (initalized) return;

    initalized = true;

    _commFrame = createCommunicationFrame();
    window.addEventListener("message", (e) => {
      if (e.origin !== destinationOrigin || "ready" !== e.data) return;

      ready = true;
      send();
    });
    document.body.appendChild(_commFrame);
  };

  init();
  return {
    send,
  };
})("https://add.myscoop.news", "https://add.myscoop.news/ga-sync-v2/");

export const trackAction = function (eventData) {
    Tracker.send({
      hitType: "event",
      ...eventData
    });

};

