import React from 'react';
import Social from './Social';
import { trackAction } from '../util/Tracking'

const Details = ({ article }) => {
    const SHARE_URLS = {
        twitter: `http://www.twitter.com/intent/tweet?hashtags=Scoop&text=${encodeURI(`Shared via Scoop`)}&url=${encodeURI(article.url)}`,
        linkedin: `https://www.linkedin.com/shareArticle?mini=true&source=${encodeURI('Shared via Scoop')}&url=${encodeURI(article.url)}`,
        email: `mailto:?body=${encodeURI(article.url)}&subject=${encodeURI('Shared via Scoop: ' + article.title)};`,
        facebook: `https://www.facebook.com/sharer.php?hashtag=${encodeURIComponent('#Scoop')}&quote=${encodeURI(`Shared via Scoop`)}&u=${encodeURI(article.url)}`
    }

    const getDescription = () => {
        return article.content ? (article.content.length > 200 ? `${article.content.substring(0, 200)}...` : article.content) : (article.description && article.description.length > 200 ? `${article.description.substring(0, 200)}...` : article.description);
    }

    const renderMedia = () => {
        let url = new URL(article.url);
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
        if (article.url.match(new RegExp(regExp)) && url.searchParams && url.searchParams.get('v')) {
            return <iframe title="Video" className="article-details__image" width="850" height="500" src={`https://www.youtube.com/embed/${url.searchParams.get('v')}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        } else {
            return <img src={article.urlToImage} alt="Article" className="img-fluid" />
        }
    }

    const shareClick = (e, social) => {
        const width = 800,
            height = 600,
            left = (window.screen.width - width) / 2,
            top = (window.screen.height - height) / 4;

        if (social === 'email') {
            var mail = document.createElement("a");
            mail.href = SHARE_URLS[social];
            mail.click();
        } else {
            window.open(SHARE_URLS[social], "_blank", `width=${width}, height=${height}, left=${left}, top=${top}`);
        }
    }

    return (
        <>
            {article ?
                <div className="article-details">
                    <div className="article-details__title">{article.title}</div>
                    <div className="article-details__image-wrapper">
                        {renderMedia()}
                    </div>
                    <div className="article-details__description">{getDescription()}</div>
                    <div className="row article-details__buttons">
                        <div className="col-6">
                            <div className={`article-details__more-in-${article.category}`} onClick={() => {
                                trackAction({
                                    eventCategory: 'Feed',
                                    eventAction: 'Click Read Full Story',
                                    eventLabel: article.title,
                                    storyCategory: article.category
                                });
                                window.open(article.url)
                            }}>Read Full Story</div>
                        </div>
                        <div className="col-6">
                            <Social shareLevel="article" location="details" outerClass="header__social" innerClass="header__social-logo--big" textClass="article-details__share" clickHandler={shareClick} />
                        </div>
                    </div>
                </div>
                : 'No article.'}
        </>
    );
};

export default Details;