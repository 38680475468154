import React, { useEffect, useState, useContext } from 'react';
import { axiosInstance as axios } from '../api/axios';
import Grid from '../components/Grid';
import Loader from 'react-loader-spinner';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { Helmet } from "react-helmet";
import AreaPicker from '../components/AreaPicker';
import AppContext from '../context/AppContext';

const HomePage = ({ history: { location: { pathname } } }) => {
    const { state: { searchTerm, currentCategory, localArea }, setCategory } = useContext(AppContext);
    const [articles, setArticles] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [partialFetched, setPartialFetched] = useState(true);
    const [options, setOptions] = useState({ limit: 20, offset: 100 });
    const [done, setDone] = useState(false);

    const getData = async (limit = 100, offset = 0, withLoader = true, withPartial = false, pathChanged = true) => {
        try {
            withLoader && setLoaded(false);
            withPartial && setPartialFetched(false);
            let response;
            if (pathname === '/local') {
                response = await axios.get(`news-scoop?format=full&limit=${limit}&offset=${offset}&location=${localArea}`);
            } else {
                response = await axios.get(`news-scoop?category=${pathname.substring(1)}&format=full&limit=${limit}&offset=${offset}`);
            }
            pathChanged ? setArticles(response.data) : setArticles([...articles, ...response.data]);
            response.data.length < limit && setDone(true);
            withLoader && setLoaded(true);
            withPartial && setPartialFetched(true);
        } catch (error) {
            console.error(error);
        }
    }

    const callback = () => {
        if (partialFetched && !done) {
            setPartialFetched(false);
            setOptions({ limit: options.limit, offset: options.offset + options.limit });
            getData(options.limit, options.offset, false, true, false);
        }
    }
    useBottomScrollListener(callback, 1000);

    useEffect(() => {
        setOptions({ limit: 20, offset: 100 });
        setDone(false);
        if (pathname !== '/local' || localArea) {
            getData();
        } else {
            setLoaded(false);
        }
        setCategory(pathname.substring(1) || 'latest'); // local is also a "category"
        // eslint-disable-next-line
    }, [pathname, localArea]);

    const filterArticles = () => {
        return articles.filter(article => (article.title && article.title.match(new RegExp(searchTerm, "i")))
            || (article.description && article.description.match(new RegExp(searchTerm, "i"))));
    }

    return (
        <div>
            <Helmet>
                <title>Scoop - Celebrity Dirt, Drama, and Dating</title>
            </Helmet> {
                pathname === '/local' && <AreaPicker></AreaPicker>
            }
            {loaded &&
                <div className="scoop-container">
                    <Grid articles={filterArticles()} category={currentCategory} showHeader={pathname === '/local' ? false : true} />
                    {!partialFetched &&
                        <div className="scoop-fetch">
                            <Loader
                                type="ThreeDots"
                                color="#DDDDDD"
                            />
                        </div>
                    }
                </div>}
            {
                (pathname === '/local' ? (!loaded && localArea) : !loaded) &&
                <div className="scoop-loader">
                    <Loader
                        type="Grid"
                        color="#FF008A"
                    />
                </div>
            }
        </div >
    );
};

export default HomePage;