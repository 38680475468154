import React from 'react';
import GridArticle from './GridArticle';

const VerticalMore = ({ articles }) => {
    return (
        <div className="scoop-vertical-more">{
            articles.map((article, index) => {
                return <div className="row" key={index}>
                    <GridArticle article={article} forcedType="grid"/>
                </div>
            })
        }</div>
    );
};

export default VerticalMore;