import React, { createRef } from 'react';
import { NavLink } from 'react-router-dom';
// import DeleteIcon from '@material-ui/icons/Delete';
// import StarIcon from '@material-ui/icons/Star';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import ThumbUpIcon from '@material-ui/icons/ThumbUp';
// import ThumbUpIconOutlined from '@material-ui/icons/ThumbUpOutlined';
// import ThumbDownIcon from '@material-ui/icons/ThumbDown';
// import ThumbDownIconOutlined from '@material-ui/icons/ThumbDownOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Social from './Social';


/// alreadyRead -> viewed and pinned aren't used in main feed;
///special feed can be "viewed" and "pinned"
const ListArticle = ({ article, handleShareClick, newsClick, logo }) => {
   let { _id: id, title, urlToImage, publishedAt, read, pinned, liked, disliked, source, placeholder, isAd, imageUrlsObject } = article;
   const modalRef = createRef();
   const getDateOrInfo = (date) => {
      var options = { year: 'numeric', month: 'short', day: 'numeric' };
      if (isAd)
         return 'Sponsored Content';
      return new Date(date).toLocaleDateString('en-US', options);
   }

   const getImage = (image) => {
      if (image === null || image === undefined) {
         return 'default.png'
      }
      return imageUrlsObject && (imageUrlsObject['scaled'] ? imageUrlsObject['scaled'] : urlToImage);
   }
   const getTitle = () => {
      if (!title) {
         return (<i>No title</i>);
      }
      return title;
   }


   const stopPropagation = (e) => {
      e.stopPropagation();
      e.preventDefault();
   }

   const shareClick = (e) => {
      stopPropagation(e);
      modalRef.current.classList.toggle("show");
   }

   const closeClick = (e) => {
      stopPropagation(e);
      modalRef.current.classList.toggle("show");
   }

   return (
      <NavLink to={`/details/${id}`} className="scoop-list-item" onClick={newsClick}>
         <div className="scoop-list-item__left">
            <img src={getImage(urlToImage)} className="scoop-list-item__image" alt="..."></img>
         </div>
         <div className="scoop-list-item__right">
            <h5 className="scoop-list-item__title">{getTitle()}</h5>
            <p className="scoop-list-item__date" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}> {getDateOrInfo(publishedAt)}</p>
            <div className="scoop-list-item__actions">
               <Tooltip title="Share" enterDelay={500}><ShareOutlinedIcon htmlColor='#198FA9' onClick={shareClick} /></Tooltip>
               {<Tooltip title={source.name} enterDelay={500} className="scoop-list-item__logo">{
                  logo ?
                     <img alt="Logo" src={`data:image/svg+xml;base64,${logo}`}></img> :
                     <span>{(source.name)}</span>
               }</Tooltip>}
            </div>
         </div>
         <div className="share-modal" ref={modalRef} onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
            <CancelOutlinedIcon className="share-modal__close" onClick={closeClick} />
            <Social shareLevel="article" articleTitle={title} location="list" innerClass="share-modal__share-icon" textClass="share-modal__title" clickHandler={handleShareClick} color="white" />
         </div>
      </NavLink>
   );
}

export default ListArticle;