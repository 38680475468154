import React, { useEffect, useContext, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import AppContext from '../context/AppContext';
import Menu from './Menu';
import { NavLink } from 'react-router-dom';
import CWSButton from './CWSButton';
import { axiosInstance as axios } from '../api/axios';
import SearchIcon from '@material-ui/icons/Search';
// import Social from './Social';
import { trackAction } from '../util/Tracking';
const footerItems = [
   'About Us',
   'Contact',
   'Terms of Use',
   'Privacy Policy',
   'Copyright Policy',
   'Do Not Sell My Personal Info',
   'Uninstall',
   'Get Scoop',
   'Subscribe'
];

const URLS = {
   cws: 'https://chrome.google.com/webstore/detail/scoop-news-fun/fpknilpohkoogikcmohlokolcknkagoi'
};
const SEARCH_ANALYTICS_TIMEOUT = 2000;

const Header = ({ history }) => {
   const { state: { categories, currentCategory }, setCategories, setSearchTerm } = useContext(AppContext);
   const [term, setTerm] = useState('');
   const [isSticky, setIsSticky] = useState(false);
   const currentTimer = useRef();
   const handleSearch = ({ target: { value } }) => {
      if (value && (value.length >= term.length || !currentTimer.current)) {
         clearTimeout(currentTimer.current);
         currentTimer.current = setTimeout(() => {
            currentTimer.current = '';
            trackAction({
               eventCategory: 'Feed',
               eventAction: 'Search ' + currentCategory,
               eventLabel: value
            });
         }, SEARCH_ANALYTICS_TIMEOUT);
      }
      setSearchTerm(value);
      setTerm(value);
   }


   const onScroll = () => {
      if (window.pageYOffset || document.documentElement.scrollTop > 500 && !isSticky) {
         setIsSticky(true);
      } else if (window.pageYOffset || document.documentElement.scrollTop <= 500 && isSticky) {
         setIsSticky(false);
      }
   }

   useEffect(() => {
      const fetchData = async () => {
         try {
            const { data } = await axios.get("categories");
            data.push('local');
            setCategories(data);
         } catch { }
      }
      fetchData();
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      window.addEventListener('scroll', onScroll);
      return () => {
         window.removeEventListener('scroll', onScroll);
      }
      // eslint-disable-next-line
   }, [isSticky]);

   const onMenuItemClick = category => {
      var staticItems = ['terms of use', 'privacy policy', 'copyright policy', 'uninstall'];
      if (category === 'get scoop') {
         trackAction({
            eventCategory: 'Get Extension',
            eventAction: 'Get Scoop from header'
         });
         window.open(URLS['cws']);
      }
      if (category === 'about us') {
         window.location = 'about';
      }
      if (category === 'do not sell my personal info') {
         window.location = 'opt-out-request';
      }
      if (staticItems.includes(category)) {
         var url = category.replace(/\s/g, "-");
         window.location = url ;
      }
      else {
         history.push(`/${category}`);
      }

   }

   return (
      <>
         <div className="header-container">
            <div className={`row header ${isSticky ? 'sticky' : ''}`}>
               <div className={`col-3 header__align scoop__align-start ${isSticky ? 'search-sticky' : ''}`}>
                  <div className="row header__align">
                     <SearchIcon className="header__search-icon" />
                     <input placeholder="Search" value={term} onChange={handleSearch} className="header__search-input"></input>
                  </div>
               </div>
               <div className="col-6">
                  <div className="row">
                     <div className="col-12 header__align scoop__align-center">
                        <img src="/logo.svg" alt="Scoop" className={`header__logo ${isSticky ? 'sticky' : ''}`} onClick={() => { history.push('/') }}></img>
                        {isSticky &&
                           <span className="header__sticky-category">
                              {categories.includes(currentCategory) && currentCategory}
                           </span>
                        }
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12  header__align scoop__align-center">
                        <div className={`header__tagline ${isSticky ? 'hide' : ''}`}>Celebrity Dirt, Drama, and Dating</div>
                     </div>
                  </div>
               </div>
               <div className="col-3 header__align scoop__align-end">
                  <div className="row">
                     <Menu menuItems={categories} footerItems={footerItems} onClick={onMenuItemClick} sticky={isSticky} />
                     {!isSticky && <CWSButton />}
                  </div>
               </div>
            </div>
            <div className="scoop__header-top-divider"></div>
         </div>
         {/* <div className="row scoop-menu">
            <div> */}
               {/* <Social outerClass="header__social" innerClass="header__social-logo" /> */}
               {/* {
                  categories.map((item, index) => <NavLink to={`/${item}`} className={`scoop-expand-menu ${item === currentCategory ? (`${item}-active`) : ''}`} activeClassName={`${item}-active`} key={index}>{item}</NavLink>)
               }
               <sup className="scoop-menu__new">new</sup>
            </div>
         </div> */}
         {/* <div className="scoop__header-bottom-divider"></div> */}
      </>
   );
};

export default withRouter(Header);