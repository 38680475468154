import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState('');
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name === '' || email === '' || feedback === '' || (email !== '' && errors.email !== '')) {
            setErrors({ ...errors, ...{ final: 'Form is not valid!' } });
        } else {
            let response = await submitFeedback('https://api.sideviral.com/api/v1/submit', {
                name: name,
                email: email,
                feedback: feedback
            });
            if (response.status === 200) {
                setMessage('Your feedback is submitted sucessfully.');
                setName('');
                setEmail('');
                setFeedback('');
            } else {
                setMessage('Something went wrong. Try again.');
            }
        }
    }

    const submitFeedback = async (url = '', data = {}) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return response;
    }

    const handleChange = (e) => {
        e.preventDefault();
        let errors = { name: '', email: '', feedback: '' };
        switch (e.target.name) {
            case 'name':
                setName(e.target.value);
                errors.name = e.target.value.length < 1 ? 'Name cannot be empty.' : '';
                break;
            case 'email':
                setEmail(e.target.value);
                errors.email = validEmailRegex.test(e.target.value) ? '' : 'Email is not valid.';
                break;
            case 'feedback':
                setFeedback(e.target.value);
                errors.feedback = e.target.value.length < 1 ? 'Feedback cannot be empty.' : '';
                break;
            default:
                break;
        }
        setErrors(errors);
    }

    return (
        <div className="scoop-legal">
            <h1 className="scoop-legal__title">Contact</h1>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter name" name="name" value={name} onChange={(e) => handleChange(e)} className="scoop-legal__field" />
                    <Form.Text className="text-muted">{errors.name}</Form.Text>
                </Form.Group>

                <Form.Group controlId="email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name="email" value={email} onChange={(e) => handleChange(e)} className="scoop-legal__field" />
                    <Form.Text className="text-muted">{errors.email}</Form.Text>
                </Form.Group>

                <Form.Group controlId="feedback">
                    <Form.Label>Feedback</Form.Label>
                    <Form.Control as="textarea" rows="3" placeholder="Provide us feedback" name="feedback" value={feedback} onChange={(e) => handleChange(e)} className="scoop-legal__field" />
                    <Form.Text className="text-muted">{errors.feedback}</Form.Text>
                </Form.Group>

                <Form.Group controlId="error">
                    <Form.Text className="text-muted">{errors.final}</Form.Text>
                </Form.Group>

                <Form.Group controlId="submit">
                    <Button type="submit" value="Submit" className="scoop-legal__submit">Submit</Button>
                    <Form.Text className="text-muted">{message}</Form.Text>
                </Form.Group>
            </Form>
        </div>
    )
}

export default Contact;