import React, { useContext } from 'react';
import Masonry from 'react-masonry-css';
import GridArticle from './GridArticle';
import AppContext from '../context/AppContext';
import GridHeader from './Headline';

const colsGrid = {
    default: 4,
    1400: 3,
    1000: 2,
    500: 1
};
const colsList = {
    default: 1,
    // 1000: 2,
    // 500: 1
};

const Grid = ({ articles, category, more, showHeader = true }) => {
    const { state: { feedType } } = useContext(AppContext);

    return (
        <div className="scoop-grid">
            {more ? <div className="scoop-grid__header">More in {category}</div>
                :
                <>{
                    showHeader &&
                    <div>
                        <GridHeader category={category ? category : 'Latest'} lineClass={`scoop-details__${category ? category : 'latest'}-line`} />
                    </div>
                }</>
            }
            <Masonry
                breakpointCols={feedType === 'grid' ? colsGrid : colsList}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {articles.map((item, index) => {
                    return item.urlToImage ? <GridArticle article={item} key={index} /> : null
                })}
            </Masonry>
        </div >
    );
};

export default Grid;