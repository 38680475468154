import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const Subscribe = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === '' || (email !== '' && error !== '')) {
            setError('Form is not valid!');
        } else {
            let response = await submitFeedback('https://api.sideviral.com/api/v1/subscribe', {
                email: email,
            });
            if (response.status === 200) {
                setMessage('You are subscribed sucessfully.');
                setEmail('');
            } else {
                setMessage('Something went wrong. Try again.');
            }
        }
    }

    const submitFeedback = async (url = '', data = {}) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return response;
    }

    const handleChange = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
        setError(validEmailRegex.test(e.target.value) ? '' : 'Email is not valid.');
    }

    return (
        <div className="scoop-legal">
            <h1 className="scoop-legal__title">Subscribe</h1>
            <Form onSubmit={(e) => handleSubmit(e)}>

                <Form.Group controlId="email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name="email" value={email} onChange={(e) => handleChange(e)} className="scoop-legal__field" />
                    <Form.Text className="text-muted">{error}</Form.Text>
                </Form.Group>

                <Form.Group controlId="submit">
                    <Button type="submit" value="Submit" className="scoop-legal__submit">Submit</Button>
                    <Form.Text className="text-muted">{message}</Form.Text>
                </Form.Group>
            </Form>
        </div>
    )
}

export default Subscribe;