import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Social from './Social';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        fill: '#333',
        cursor: 'pointer',
        width: '40px',
        height: '40px',
        marginRight: '30px'
    },
    menu: {
        width: '215px',
        maxWidth: '215px',
        maxHeight: 'none',
        [theme.breakpoints.down('xs')]: {
            maxHeight: '400px',
            top: '60px !important',
            right: '20px !important',
            left: 'unset !important'
        },
    },
    menuItem: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: '700',
        cursor: 'pointer',
        minHeight: '20px',
        margin: '10px',
        padding: '0px',
        color: '#1b2023',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#f16334',
            color: '#ffffff'
        }
    },
    footerItem: {
        fontFamily: 'Montserrat',
        minHeight: '20px',
        fontSize: '14px',
        fontWeight: '500',
        cursor: 'pointer',
        margin: '3px 10px',
        padding: '0px',
        color: '#1b2023',
        '&:hover': {
            backgroundColor: '#f16334',
            color: '#ffffff'
        },
    },
    additionalItem: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: '500',
        display: 'flex',
        justifyContent: 'center',
        '&:last-child': {
            padding: 0,
            marginBottom: '10px',
            marginTop: '-10px'
        }
    },
    image: {
        width: '30px',
        marginRight: '5px',
        marginLeft: '5px',
        cursor: 'pointer'
    },
    menuItemGutters: {
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    divider: {
        margin: '10px'
    }
}));

const CustomMenu = ({ menuItems, footerItems, onClick, sticky }) => {

    const classes = useStyles();
    const [anchor, setAnchor] = useState(null);


    const handleMenuIconClick = (event) => {
        setAnchor(event.currentTarget);
    };

    return (
        <div>
            <div className={`scoop-collapsed-menu ${sticky ? 'scoop-collapsed-menu--important' : ''}`}>
                <MenuIcon classes={{ root: classes.menuButton }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuIconClick} />
                <Menu
                    id="simple-menu"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={() => { setAnchor(null) }}
                    style={{ top: '25px', left: '5px' }}
                    classes={{ paper: classes.menu }}
                >
                    {menuItems.map(item => <MenuItem
                        key={item}
                        classes={{ root: classes.menuItem, gutters: classes.menuItemGutters }}
                        onClick={() => { setAnchor(null); onClick(item); }}
                    >
                        {item}
                    </MenuItem>)}
                    <Divider classes={{ root: classes.divider }} />
                    {footerItems.map(item => <MenuItem
                        key={item}
                        classes={{ root: classes.footerItem, gutters: classes.menuItemGutters }}
                        onClick={() => { setAnchor(null); onClick(item.toLowerCase()); }}
                    >
                        {item}
                    </MenuItem>)}
                    <Divider classes={{ root: classes.divider }} />
                    <MenuItem
                        classes={{ root: classes.additionalItem, gutters: classes.menuItemGutters }}
                    >
                        <Social location="menu" color="black" innerClass={classes.image} />
                    </MenuItem>
                    <MenuItem
                        classes={{ root: classes.additionalItem, gutters: classes.menuItemGutters }}
                    >
                        &copy; 2020 Scoop
                    </MenuItem>
                    <MenuItem
                        classes={{ root: classes.additionalItem, gutters: classes.menuItemGutters }}
                    >
                        All Rights Reserved
                    </MenuItem>
                </Menu>
            </div>
        </div >
    );
};

export default withRouter(CustomMenu);