import React from 'react';
import FeedType from './FeedType';

const Headline = ({ category, lineClass }) => {

    const getDate = (date = Date.now()) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(date).toLocaleDateString('en-US', options);
    }

    return (
        <>
            <div className="row col-12">
                <div className="col-8 scoop-details__category">
                    {category}
                    <span className="scoop-details__date">{getDate()}</span>

                </div>
                <div className="col-4 scoop__align-end">
                    <FeedType></FeedType>
                </div>
            </div>
            <div className={lineClass}></div>
        </>


    );
};

export default Headline;