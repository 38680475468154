import React, { useContext, useReducer } from 'react';
import AppContext from './AppContext';
import reducer from './reducer';

const ContextProvider = props => {
    const initialState = useContext(AppContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    const actions = {
        setCategory: (category) => {
            dispatch({ type: "SET_CATEGORY", payload: category });
        },
        setArticles: (articles) => {
            dispatch({ type: "SET_ARTICLES", payload: articles });
        },
        setCategories: (categories) => {
            dispatch({ type: "SET_CATEGORIES", payload: categories });
        },
        setSources: (sources) => {
            let logoMap = {};
            sources.forEach(source => logoMap[source.name] = source.logo);
            dispatch({ type: "SET_SOURCES", payload: logoMap });
        },
        setSearchTerm: (term) => {
            dispatch({ type: "SET_SEARCH_TERM", payload: term });
        },
        setFeedType: (feedType) => {
            localStorage.setItem('feed', JSON.stringify({ feedType, date: Date.now() }));
            dispatch({ type: "SET_FEED_TYPE", payload: feedType });
        },
        setLocalArea: (localArea) => {
            dispatch({ type: "SET_LOCAL_AREA", payload: localArea });
        },
    }

    return (
        <AppContext.Provider value={{ state, ...actions }
        }  {...props} />
    );
}

export default ContextProvider;
