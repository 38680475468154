import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import Social from './Social';
import { trackAction } from '../util/Tracking';

const Footer = () => {
    const URLS = {
        cws: 'https://chrome.google.com/webstore/detail/scoop-news-fun/fpknilpohkoogikcmohlokolcknkagoi',
        feeback: 'https://airtable.com/shrFK1VmxnLjgHYuD',
    };
    const footerRef = createRef();
    const previewRef = createRef();
    const dividerRef = createRef();
    const rotateButtonRef = createRef();
    const copyRPreviewRef = createRef();
    const previewEndRef = createRef();

    const onClick = () => {
        footerRef.current.classList.toggle('show');
        previewEndRef.current.classList.toggle('hide');
        copyRPreviewRef.current.classList.toggle('hide');
        dividerRef.current.classList.toggle('expand');
        rotateButtonRef.current.classList.toggle('rotate');
    }

    return (
        <>
            <div className="scoop__footer-divider" ref={dividerRef}></div>
            <div className="scoop-footer row" ref={footerRef}>
                <div className="col-12">
                    <div className="row footer-preview" ref={previewRef}>
                        <div className="col-4">
                            <div className="row scoop-footer__copyright" ref={copyRPreviewRef}>
                                &copy; 2020 Scoop, All Rights Reserved.
                           </div>
                        </div>
                        <div className="col-4 footer-preview__expand">
                            <div className="row footer-preview__button" onClick={onClick}>
                                <img src="/white_arrow.svg" alt="Expand" className="footer-preview__expand-arrow" ref={rotateButtonRef}/>
                            </div>
                        </div>
                        <div className="col-4 footer-preview__end">
                            <div className="row" ref={previewEndRef}>
                                <div className="col-4 offset-1 footer-preview__link">
                                 <a href="/about" className="scoop-footer__link">About Us</a>
                                </div>
                                <div className="col-4 footer-preview__link">
                                    <a href="/terms-of-use" className="scoop-footer__link">Terms of Use</a>
                                </div>
                                <div className="col-3 footer-preview__link" onClick={onClick}>
                                    More
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="row scoop-footer__content">
                        <div className="col-2">
                            <div className="row scoop-footer__center">
                                <div className="col-12">
                                    <div className="row scoop-footer__title">
                                        Connect
                                </div>
                                    <div className="row">
                                        <a href="/about" className="scoop-footer__link">About Us</a>
                                    </div>
                                    <div className="row">
                                        <a href="/contact" className="scoop-footer__link">Contact</a>
                                    </div>
                                    <div className="row scoop-footer__link">
                                        <a href="/uninstall" className="scoop-footer__link">Uninstall</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row scoop-footer__center">
                                <div className="col-12">
                                    <div className="row scoop-footer__title">
                                        Legal
                                </div>
                                    <div className="row scoop-footer__link">
                                        <a href="/terms-of-use" className="scoop-footer__link">Terms of Use</a>
                                    </div>
                                    <div className="row scoop-footer__link">
                                        <a href="/privacy-policy" className="scoop-footer__link">Privacy Policy</a>
                                    </div>
                                    <div className="row scoop-footer__link">
                                        <a href="/copyright-policy" className="scoop-footer__link">Copyright Policy</a>
                                    </div>
                                    <div className="row">
                                        <a href="/opt-out-request" className="scoop-footer__link">Do Not Sell My Personal Info</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="row scoop-footer__center">
                                {/* <div className="col-12">
                                    <div className="row scoop-footer__title">
                                        Subscribe
                                </div>
                                    <div className="row">
                                        <input className="scoop-footer__input" placeholder="Enter Email" />
                                        <div className="scoop-footer__submit">Submit</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="row scoop-footer__center">
                                <div className="col-12">
                                    <div className="row scoop-footer__title">
                                        Follow
                                </div>
                                    <Social location="footer" color="white" outerClass="row" innerClass="scoop-footer__social" />
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="row scoop-footer__center scoop-footer__last">
                                <div className="col-12">
                                    <div className="row scoop-footer__title">
                                        Get Scoop
                                </div>
                                    <div className="row scoop-footer__cws">
                                        <img src="/cws.svg" alt="cws" onClick={() => {
                                            trackAction({
                                                eventCategory: 'Get Extension',
                                                eventAction: 'Get Scoop from footer'
                                            });
                                            window.open(URLS['cws'])
                                        }}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row scoop-footer__copyright">
                        &copy; 2020 Scoop, All Rights Reserved.
                </div>
                </div>
            </div>
        </>
    );
};

export default Footer;